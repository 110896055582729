/* LANDING PAGE STYLES */

@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Sen:wght@400..800&display=swap");
.hind-light {
  font-family: "Hind", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.hind-regular {
  font-family: "Hind", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.hind-medium {
  font-family: "Hind", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.hind-semibold {
  font-family: "Hind", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.hind-bold {
  font-family: "Hind", sans-serif;
  font-weight: 700;
  font-style: normal;
}

body,
html {
  height: 100%;
  margin: 0;
}
body {
  display: grid;
  grid-template-rows: 1fr auto; /* 1fr for content, auto for footer */
  min-height: 100vh;
}

.navbar-lp {
  background-color: rgb(255, 255, 255);
  height: 100px;
}

nav .navbar-brand-lp {
  font-family: "Hind", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 9vw;
  text-transform: uppercase;
}

.navbar-nav .btn-primary {
  color: #000;
  font-weight: bold;
  background-color: #9fe870;
  border: 0px;
}

.navbar-nav .btn-primary:hover {
  color: #000;
  font-weight: bold;
  background-color: #89fe41;
  border: 0px;
}

/* HeroSection with overlay */
.hero-section {
  position: relative; /* Ensure the parent is positioned */
  /* background: url("/public/images/background-image.jpg") no-repeat center center; */
  background-size: cover;
  color: #ffffff;
  padding: 50px 0;
  text-align: center;
}

.hero-section .hero-title {
  font-family: "Hind", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 80pt;
  letter-spacing: -2pt;
  text-transform: uppercase;
}

.hero-section .hero-sub {
  font-family: "Hind", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 30pt;
}

.hero-section .btn-primary {
  color: #000;
  font-weight: bold;
  background-color: #9fe870;
  border: 0px;
}

.hero-section .btn-primary:hover {
  color: #000;
  font-weight: bold;
  background-color: #89fe41;
  border: 0px;
}

/* Container for the showcase items */
.showcase-section {
  margin: 20px 0;
  /* height: 650px; */
}
.showcase-section h2 {
  font-family: "Hind", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 60pt;
  letter-spacing: -5px;
  text-transform: uppercase;
  text-align: center;
  color: #000;
  padding-top: 10rem;
}

.showcase-section p {
  font-size: 15pt;
  text-align: center;
  color: #163300;
}

.showcase-section .showcaseTitle {
  font-family: "Hind", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}

.showcase-section .lead {
  color: #000;
  text-align: left;
  font-size: 1rem;
  background-color: white;
  padding: 10px;
  border-radius: 15px;
  margin-top: auto;
}

.showcase-section .btn-primary,
.showcase-section .btn-primary:active {
  width: 80px; /* Adjusts the width of the button area */
  height: 80px; /* Adjusts the height of the button area, making it circular */
  display: flex;
  margin: 1rem;
  align-items: center; /* Centers the icon vertically */
  justify-content: center; /* Centers the icon horizontally */
  border: none; /* Removes any default border */
  background-color: #868685; /* Button color, can match Bootstrap primary or any color */
  border-radius: 50%; /* Makes the button background circular */
}

.showcase-section .btn-primary:focus,
.showcase-section .btn-primary:hover {
  background-color: #9fe870 !important; /* Darker shade for hover/focus */
}


/* To make the cards taller */
.showcase-section .card-cover {
  height: 50vh; /* Sets the height of the card. Adjust vh (viewport height) value as needed */
  width: 25vw;
}


.carousel-item {
  float: none;
  display: inline-block;
  width: auto; /* Adjust based on your card width */
  transition: transform 0.6s ease-in-out; /* Smooth transition for the sliding effect */
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: inline-block;
}

/* Adjust the view of the carousel to show only one card fully at a time */
.carousel {
  overflow: hidden;
  display: block; /* This ensures the carousel has a defined width */
}

/* Optional: Customizing the control buttons */
.carousel-control-prev,
.carousel-control-next {
  width: 4%; /* Smaller control buttons */
  z-index: 2;
}


/* FeatureSection */
.features-section {
  background-color: rgb(255, 255, 255);
  padding: 50px 0;
}

.feature-image img {
  /* background: url("/public/images/background-image.jpg") no-repeat center center; */
  background-size: cover;
  width: 500px;
}

.feature-item {
  margin-bottom: 30px;
  align-items: center;
  text-align: center;
}

/* AboutSection */
.about-section {
  background-color: #fff;
  color: #000;
  padding: 50px 0;
  text-align: center;
}

.about-section h2 {
  font-family: "Hind", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 40pt;
  letter-spacing: -3px;
  text-transform: uppercase;
  text-align: center;
  color: #163300;
}

.about-section .btn-primary {
  color: #000;
  font-weight: bold;
  background-color: #9fe870;
  border: 0px;
}

.about-section .btn-primary:hover {
  color: #000;
  font-weight: bold;
  background-color: #89fe41;
  border: 0px;
}

/* Testimonials */
.testimonials {
  background-color: #e9ecef;
  padding: 50px 0;
}

.testimonial-item {
  margin: 0 auto;
  max-width: 400px;
}

/* CTASection */
.cta-section {
  background-color: #9fe870;
  color: #000;
  padding: 50px 0;
  text-align: center;
}

.cta-section h2 {
  font-family: "Hind", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 40pt;
  letter-spacing: -3px;
  text-transform: uppercase;
  text-align: center;
  color: #163300;
}

.cta-section p {
  font-size: 15pt;
  text-align: center;
  color: #163300;
}

.cta-section .btn-primary {
  color: #9fe870;
  font-weight: bold;
  background-color: #224d00;
  border: 0px;
}

.cta-section .btn-primary:hover {
  color: #9fe870;
  font-weight: bold;
  background-color: #163300;
  border: 0px;
}

.articles-section {
  /* background-color: rgba(55, 255, 20, 0.576); */
  background-color: #9fe870;
  color: #000;
  padding: 2rem 1rem;
}

.articles-section h2 {
  font-family: "Hind", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 40pt;
  letter-spacing: -3px;
  text-transform: uppercase;
  text-align: center;
  color: #163300;
}

.articles-section .btn-primary {
  color: #9fe870;
  font-weight: bold;
  background-color: #224d00;
  border: 0px;
}

.articles-section .btn-primary:hover {
  color: #9fe870;
  font-weight: bold;
  background-color: #163300;
  border: 0px;
}

.card-body {
  display: flex;
  flex-direction: column;
}

/* Overlay Styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 117, 216, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-text {
  font-size: clamp(2rem, 5vw, 4rem); /* Responsive font size using clamp */
  font-weight: bolder;
  text-align: center;
  line-height: 3rem;
  color: #ffffff;
  transform: rotate(-15deg); /* Angle the text */
  -webkit-transform: rotate(-15deg); /* Safari */
  text-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25);
}

/* Footer */
.footer-lp {
  background-color: rgba(22, 51, 0, 0.08);
  color: #000;
  /* padding: 40px 0; */
}

.footer a {
  color: #000;
}

.footer h5 {
  margin-bottom: 20px;
}

.footer {
  margin-top: auto; /* Pushes the footer to the bottom */
}


/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .navbar {
    padding: 0.25rem 1rem;
  }
  .navbar .navbar-brand {
    font-size: 6vw;
  }

  .hero-section .hero-title {
    font-size: 15vw;
  }
}

@media (max-width: 500px) {
  .overlay-text, .card-overlay-text {
    font-size: 1.5rem;
  }
  .navbar .navbar-brand {
    font-size: 8vw;
  }

  .hero-section {
    padding: 20px 0;
  }
}

@media (max-width: 375px) {
  .overlay-text, .card-overlay-text {
    font-size: 1.5rem;
  }
  .card {
    min-height: 400px;
  }

  .card .lead {
    font-size: .95rem;
  }
}