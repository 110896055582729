/* Basic Styles for the Sidebar */
.sidebar {
    width: 280px !important;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%); /* Hidden by default */
    transition: transform 0.3s ease-in-out;
  }

/* Content within the sidebar should be scrollable if it exceeds the viewport height */
.sidebar-content {
  height: 100%;
  overflow-y: auto;
  max-width: 280px; /* Ensure content does not exceed 280px width */
  width: 100%;
}

.hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050; /* Ensure it's above other content */
  background-color: #f8f9fa; /* Match the sidebar background or adjust as needed */
  border: none;
  padding: 15px;
  font-size: 24px; /* Adjust size as needed */
  display: none; /* Initially hidden */
}

/* Collapsed state for the sidebar */
.sidebar.collapsed {
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

/* Additional styling to ensure a visually appealing sidebar */
.sidebar a {
    color: #333;
    text-decoration: none; /* Optional: Adjust color to fit your theme */
  }
  
  .sidebar a:hover {
    text-decoration: underline;
  }
  
  .sidebar .brand {
    font-weight: bold;
    font-size: 24px; /* Optional: Adjust size to fit your branding */
  }

  
/* Adjustments for mobile and smaller screens */
@media (max-width: 1024px) {
  .sidebar {
    position: fixed;
    width: 100% !important; /* Full screen on mobile sizes */
    height: 100%;
    transform: translateX(-100%); /* Start off-screen */
    z-index: 1049;
  }
  .sidebar-content {
    position: fixed;
    margin-top: 60px;
  }

  .sidebar.collapsed {
    transform: translateX(0); /* Show sidebar when toggled */
  }

  .hamburger-menu {
    display: block;
  }

  main {
    padding-top: 50px;
  }
}