/* Admin.css */
#admin-wrapper {
  min-height: 100vh;
}

#sidebar-wrapper {
  min-width: 250px;
  max-width: 250px;
  overflow-y: auto;
}

/* Add CSS for the content container to only apply padding here */
.content-container {
  padding: 20px;
}

/* Ensure navbar stretches across the width if needed */
#page-content-wrapper > .navbar {
  width: 100%; /* This ensures navbar takes full width */
}
#page-content-wrapper {
  flex-grow: 1;
}


/* Example to adjust button placement */
#menu-toggle {
  margin-right: 15px; /* Adjust as per your layout */
}


.toggled #sidebar-wrapper {
  margin-left: -250px;
}

.toggled #page-content-wrapper {
  width: 100%;
}

/* Adjustments for better visual spacing */
.list-group-item {
  border: none;
  border-bottom: 1px solid #eeeeee;
}

/* Sidebar Styling */
.sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}
