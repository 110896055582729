/* Refactored GhostWriterApp.css to align with Bootstrap conventions */

.ghost-writer-content {
  padding-bottom: 80px; /* Adjust based on the footer's height to ensure visibility */
}

/* Styles for the floating footer */
footer.footer-module, .footer {
  background-color: #282c34; /* Footer background color */
  color: white;
  text-align: center;
  border-top: 1px solid #dee2e6; /* Adds a border for visual separation */
  padding: 1rem; /* Adjust padding to suit your content */
}

html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 100px;
}

.moduleHeader {
  font-family: "Hind", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 40pt;
  letter-spacing: -3px;
  text-transform: uppercase;
  color: #163300;
  margin-top: 10px;
}

.module .btn-primary {
  color: #000;
  font-weight: bold;
  background-color: #9fe870;
  border: 0px;
}

.module .btn-primary:hover {
  color: #000;
  font-weight: bold;
  background-color: #89fe41;
}

.module .btn-success {
  color: #fff;
  font-weight: bold;
  background-color: #a0e1e1;
  border: 0px;
}

.module .btn-success:hover {
  color: #fff;
  font-weight: bold;
  background-color: #7daeae;
}

.module .btn-secondary {
  color: #000;
  font-weight: bold;
  background-color: #16330014;
  border: 0px;
}

.module .btn-secondary:hover {
  color: #000;
  font-weight: bold;
  background-color: #16330021;
}

.module .btn-info {
  color: #e8ebe6;
  font-weight: bold;
  background-color: #260a2f;
  border: 0px;
}

.module .btn-info:hover {
  color: #e8ebe6;
  font-weight: bold;
  background-color: #454745;
}

.module .btn-danger {
  color: #fff;
  font-weight: bold;
  background-color: #ff0000bc;
  border: 0px;
}

.module .btn-danger:hover {
  color: #fff;
  font-weight: bold;
  background-color: #ff0000;
}

.navbar-lp {
  background-color: rgb(255, 255, 255);
  height: 100px;
}

nav .navbar-brand-lp {
  font-family: "Hind", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30pt;
  letter-spacing: -3px;
  text-transform: uppercase;
}

.brand {
  font-family: "Hind", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30pt;
  letter-spacing: -3px;
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
}

.navbar-nav .btn-primary {
  color: #000;
  font-weight: bold;
  background-color: #9fe870;
}

.navbar-nav .btn-primary:hover {
  color: #000;
  font-weight: bold;
  background-color: #89fe41;
}

/* Toast Container for positioning */
.toast-container {
  top: 1rem; /* Distance from the top of the viewport */
  right: 1rem; /* Distance from the right of the viewport */
  bottom: auto; /* Override any existing bottom value */
  left: auto; /* Ensure it does not affect the horizontal positioning */
  z-index: 1050; /* High z-index to ensure it's above most other content */
}

/* Custom Toast Appearance */
.toast {
  min-width: 250px; /* Ensure sufficient width for readability */
  background-color: #d6d6d6; /* Dark background for contrast */
  color: #ffffff; /* Light text color for readability */
}

.toast-header {
  background-color: #0c7c00; /* Primary color for header */
  color: #ffffff; /* Light text color */
}

.toast-body {
  font-size: 1rem; /* Adjust based on your app's font sizing */
  font-weight: normal; /* Can be adjusted for emphasis */
}

/* Close Button Customization */
.toast .btn-close {
  color: #ffffff; /* Ensures close button is visible against the header background */
}

/* Loading Overlay Styles */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjusted for better visibility */
  z-index: 1070; /* Bootstrap modal backdrop z-index is 1050, modal is 1070 */
  display: none; /* Hidden by default */
}

.loading-overlay.show {
  display: flex; /* Shown when active */
  align-items: center;
  justify-content: center;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  color: #89fe41;
}


/* Adjustments to leverage Bootstrap's utilities and components */
/* These styles are either redundant or can be replaced with Bootstrap utilities in your JSX */

/* For example, use 'text-center' class in place of .App and .App-header text-align */
/* Use 'bg-dark', 'text-white', 'd-flex', 'flex-column', 'align-items-center', 'justify-content-center' for .App-header */

/* Custom styles to enhance Bootstrap or specific to the app's components */
.articleSummary-section,
.update-articleSummary-section,
.update-response-section {
  border: 1px solid #dee2e6; /* Bootstrap border color for consistency */
  border-radius: 0.25rem; /* Match Bootstrap's border-radius */
  background-color: #f8f9fa; /* Light background for distinction */
  padding: 1rem; /* Consistent padding */
  margin-bottom: 1rem; /* Consistent bottom margin */
}
.update-articleSummary-section h2 {
  font-size: 1rem;
}

.update-articleSummary-section h3 {
  font-size: 0.9rem;
}

.update-articleSummary-section {
  font-size: 0.9rem;
}

.articleSummary-section h3 {
  font-size: 1rem;
}

.articleSummary-content {
  font-size: 0.8rem; /* Adjust the size as needed */
}

.articleSummary-content textarea {
  font-size: 0.8rem; /* Adjust the size as needed */
}

.btn-copy {
  background-color: #28a745; /* Bootstrap 'success' color for 'copy' action */
}

.btn-copy:hover {
  background-color: #218838; /* A slightly darker shade for hover, similar to Bootstrap's hover effect */
}

/* This approach suggests removing or commenting out the previous CSS definitions that directly conflict or duplicate Bootstrap's styles. */
/* For example, .App, .App-header, button styles, etc., can be managed with Bootstrap classes directly in your JSX. */

/* Ensure to review your JSX to add or adjust Bootstrap utility classes as needed, such as 'mt-5', 'mb-4', 'btn', 'btn-primary', 'btn-secondary', 'btn-danger', 'form-control', etc. */

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slide-in {
  animation: slideIn 0.5s forwards;
}

.slide-out {
  animation: slideOut 0.5s forwards;
}

.card.uni-card.selected {
  border: 2px solid #a0e1e1; /* Bootstrap primary color */
  background-color: #9ee87036;
}

.card.uni-card .card-title{
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
}

/* .card .uni-card .card-body .card-title {
  font-size: 1rem;
} */

.card .uni-card .card-img-top, .circular {
  padding: 10px;
  width: auto;
  height: 100px;
  border-radius: 70%;
  object-fit: cover;
  margin: 0 auto;
}

.progress {
  position: inherit ;
  margin-top: 10px;
}

.progress .progress-bar {
  background-color: #a0e1e1;
}

.moduleSelectSection {
  /* position:relative; */
  padding: 5px 0;
  height: 30px;
}

.form-group-slider {
  display: flex;
  flex-direction: column;
  height: 300px; /* or whatever height you desire */
}

.slider-container {
  flex-grow: 1; /* Makes the slider's container grow and take up any available space */
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Aligns the slider to the bottom */
}

.bullet-list-images {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the start (left) */
  gap: 10px;
  border: 5px solid #9fe870;
  padding: 20px;
  border-radius: 15px;
  width: 70%;
  /* Removed height: 100% to control the height based on content */
}

.bullet-image {
  flex-grow: 1;
  width: 40%; /* Sets image width to 60% of its original size */
  height: auto; /* Maintains the aspect ratio */
}

.form-range {
  margin-top: auto; /* Pushes slider to the bottom */
}

@media (max-width: 768px) {
  .toast-container {
    width: 100%; /* Full-width on small screens */
    bottom: 0; /* Position at the bottom */
    padding: 0.5rem; /* Adjust padding for smaller screens */
  }
  .toast {
    width: calc(100% - 1rem); /* Full-width minus padding */
  }
  .footer {
    text-align: center; /* Ensures footer content is centered on smaller screens */
  }
  .modulesHero {
    width: 100%; /* ensures the image is always as wide as its container */
    height: auto; /* maintains the aspect ratio */
    object-fit: cover; /* cover the container without stretching */
  }
  
}

@media (max-width: 576px) {
  .hero-wlp .col-lg-7 {
    padding: 15px; /* Reduce padding on smaller screens */
  }
  
  .card-cover {
    height: auto; /* Adjust card height on smaller screens */
  }
  
  .bullet-list-images {
    width: 100%; /* Use full width on very small screens */
  }

  .bullet-image {
    width: 60%; /* Increase size to be more visible on small screens */
  }
}

/* this is needed to make the content scrollable on larger screens */
@media (min-width: 576px) {
  .h-sm-100 {
      height: 100%;
  }
}